import FingerprintJS from '@fingerprintjs/fingerprintjs';

const fpPromise = FingerprintJS.load();

(async () => {
  const fp = await fpPromise;
  const result = await fp.get();

  if (!result || !result.visitorId) return;

  window.dataLayer.push({
    event: 'fingerprint_ready',
    fid: result.visitorId
  });
})();
